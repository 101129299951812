<template>
  <div>
    <top-navbar></top-navbar>
    <top-navbar-mobile></top-navbar-mobile>
  </div>
</template>

<script>
import TopNavbar from '../components/TopNavbar.vue'
import TopNavbarMobile from '../components/TopNavbarMobile.vue'
export default {
  components: { TopNavbar, TopNavbarMobile },
}
</script>
