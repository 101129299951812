<template>
  <div
    class="
      hidden
      lg:flex
      fixed
      inset-x-0
      z-50
      px-20
      justify-between
      items-center
      h-[82px]
      bg-opacity-75
      text-white
      transition-colors
      duration-400
      font-ubuntu
    "
    :class="solidNavbar && 'bg-white backdrop-blur-lg text-text-primary shadow'"
  >
    <ul class="z-10 flex space-x-4 flex-1">
      <li
        v-for="(menu, index) in mainMenus"
        :key="index"
        class="font-medium pl-3 first:pl-0"
        :class="
          activeMainMenuIndex === index
            ? 'relative after:absolute after:inset-0 after:translate-y-[30px] after:border-b-4 after:border-primary text-primary'
            : ''
        "
      >
        <a
          v-if="menu.link"
          class="hover:text-primary"
          :href="menu.link"
          @click="closeAllMenu"
          >{{ menu.title }}</a
        >
        <div
          v-else
          class="cursor-pointer flex items-center group"
          @click="toggleMainMenu(index)"
        >
          <div class="group-hover:text-primary">
            {{ menu.title }}
          </div>
          <img
            src="../assets/images/svg/chevron-up.svg"
            class="h-5 w-5 ml-2 group-hover:filter-primary"
            :class="{
              'rotate-180': activeMainMenuIndex === index,
              'filter-white': !solidNavbar,
            }"
            alt="Arrow"
          />
        </div>
      </li>
    </ul>
    <a class="z-10" href="https://daihatsu.co.id" @click="closeAllMenu">
      <img
        src="../assets/images/daihatsu-logo.svg"
        class="w-[113px] h-[38px] mx-16"
        width="113"
        height="38"
        alt="Logo Daihatsu"
      />
    </a>
    <div class="flex z-10 items-center space-x-[42px] flex-1 justify-end">
      <img
        class="w-[18px] h-[17px] cursor-pointer hover:filter-primary"
        :class="solidNavbar ? '' : 'filter-white'"
        src="../assets/images/search.svg"
        alt="search icon"
        @click="toggleSearchMenu"
      />
      <img
        src="../assets/images/menu.svg"
        class="h-[26px] w-[26px] cursor-pointer hover:filter-primary"
        :class="{
          'filter-white': !solidNavbar,
          'filter-primary': secondaryMenuIsActive,
        }"
        alt="Menu"
        @click="toggleSecondaryMenu"
      />
    </div>
    <DropdownVehicles
      v-if="activeMainMenuIndex === 0"
      :products="products"
      @close="closeAllMenu"
    />
    <DropdownMenu
      v-if="activeMainMenuIndex > 0"
      :key="activeMainMenuIndex"
      :menus="mainMenus[activeMainMenuIndex].subMenus"
      @close="closeAllMenu"
    />
    <DropdownNestedMenu
      v-if="secondaryMenuIsActive"
      :menus="secondaryMenus"
      @close="closeAllMenu"
    />
    <DropdownSearch
      v-if="searchMenuIsActive"
      :products="products"
      @close="toggleSearchMenu"
    />
  </div>
</template>

<script>
import {
  vehicles,
  shoppingTools,
  afterSales,
  profile,
  ownership,
} from '../menu.js'
import DropdownMenu from './Dropdown/Menu.vue'
import DropdownNestedMenu from './Dropdown/NestedMenu.vue'
import DropdownSearch from './Dropdown/Search.vue'
import DropdownVehicles from './Dropdown/Vehicles.vue'
import 'unfetch/polyfill'

const mainMenus = [
  vehicles,
  shoppingTools,
  afterSales,
  {
    title: 'Tips Sahabat',
    link: 'https://daihatsu.co.id/tips-and-event/tips-sahabat/',
  },
]

const secondaryMenus = [profile, ownership]

export default {
  components: {
    DropdownMenu,
    DropdownNestedMenu,
    DropdownSearch,
    DropdownVehicles,
  },
  data() {
    return {
      solidNavbar: false,
      activeMenu: false,
      activeMainMenuIndex: null,
      secondaryMenuIsActive: false,
      searchMenuIsActive: false,
      mainMenus,
      secondaryMenus,
      products: [],
      scrollPosition: 0,
    }
  },
  async created() {
    await this.getProducts()
  },
  mounted() {
    window.addEventListener('scroll', this.updateNavbarTransparency)
    this.updateNavbarTransparency()
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateNavbarTransparency)
  },
  methods: {
    toggleMainMenu(index) {
      this.secondaryMenuIsActive = false
      this.searchMenuIsActive = false
      this.activeMainMenuIndex === index
        ? (this.activeMainMenuIndex = null)
        : (this.activeMainMenuIndex = index)
    },
    toggleSecondaryMenu() {
      this.activeMainMenuIndex = null
      this.searchMenuIsActive = false
      this.secondaryMenuIsActive = !this.secondaryMenuIsActive
    },
    toggleSearchMenu() {
      this.activeMainMenuIndex = null
      this.secondaryMenuIsActive = false
      this.searchMenuIsActive = !this.searchMenuIsActive
    },
    closeAllMenu() {
      this.activeMainMenuIndex = null
      this.secondaryMenuIsActive = false
    },
    updateNavbarTransparency() {
      if (window.scrollY > 100 || this.isNavbarActive) {
        this.solidNavbar = true
      } else {
        this.solidNavbar = false
      }
    },
    async getProducts() {
      try {
        const response = await fetch('https://daihatsu.co.id/api/products')
        const products = await response.json()
        this.mainMenus[0].subMenus = products.map((x) => {
          return {
            title: x.name,
            link: `https://daihatsu.co.id/product/${x.slug}/`,
          }
        })
        this.products = products
      } catch (error) {
        console.log(error)
      }
    },

    unlockScroll() {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('width')
      window.scroll({ top: this.scrollPosition, behavior: 'auto' })
    },

    lockScroll() {
      this.scrollPosition = window.pageYOffset
      document.body.style.overflow = 'hidden'
      document.body.style.top = `-${this.scrollPosition}px`
      document.body.style.width = '100%'
    },
  },
  computed: {
    isNavbarActive() {
      return (
        this.searchMenuIsActive ||
        this.secondaryMenuIsActive ||
        this.activeMainMenuIndex !== null
      )
    },
  },
  watch: {
    // solidNavbar() {
    //   this.activeMainMenuIndex = null
    //   this.secondaryMenuIsActive = false
    //   this.searchMenuIsActive = false
    // },
    isNavbarActive(newValue) {
      if (newValue) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    },
    activeMainMenuIndex() {
      this.updateNavbarTransparency()
    },
    secondaryMenuIsActive() {
      this.updateNavbarTransparency()
    },
    searchMenuIsActive() {
      this.updateNavbarTransparency()
    },
  },
}
</script>

<style>
@import url('../assets/css/main.css');
</style>
