<template>
  <div
    class="
      h-[90px]
      fixed
      bottom-0
      w-full
      flex
      justify-center
      align-top
      lg:hidden
      z-10
      font-ubuntu
    "
  >
    <div
      class="
        rounded-xl
        shadow
        px-4
        py-3
        bg-white
        z-30
        w-[96%]
        h-[90%]
        grid grid-cols-4
      "
    >
      <a
        v-for="tab in tabs"
        :key="tab.link"
        :href="tab.link"
        class="flex flex-col items-center content-between"
      >
        <img :src="tab.icon" class="h-[28px] mb-1" :alt="tab.title" />
        <div class="text-center text-[8px] font-bold text-azure w-8">
          {{ tab.title }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  data() {
    return {
      tabs: [
        {
          title: 'Request Quotes',
          icon: require('../assets/images/info/quotes.svg'),
          link: 'https://daihatsu.co.id/request-quotes',
        },
        {
          title: 'Find Dealer',
          icon: require('../assets/images/info/dealer.svg'),
          link: 'https://daihatsu.co.id/shopping/dealer',
        },
        // {
        //   title: 'Scan AR Brosur',
        //   icon: require('../assets/images/barcode-blue.svg'),
        //   link: 'https://daihatsu.co.id/scan-brosur',
        // },
        {
          title: 'Credit Simulation',
          icon: require('../assets/images/info/credit.svg'),
          link: 'https://daihatsu.co.id/simulasi-kredit',
        },
        {
          title: 'Price List',
          icon: require('../assets/images/info/price.svg'),
          link: 'https://daihatsu.co.id/price-list',
        },
      ],
    }
  },
}
</script>

<style>
@import url('../assets/css/main.css');
</style>