export const profile = {
  title: 'Daihatsu Profile',
  subMenus: [
    {
      title: 'Profile',
      link: 'https://daihatsu.co.id/profile/about-us/',
      image: require('./assets/images/menu/dp-profile.jpg'),
      description:
        'Sebagai Sahabat setiap generasi di Indonesia, Daihatsu Indonesia terus berkomitmen memberikan pelayanan terbaik sesuai dengan kebutuhan Sahabat, dan menjadikan setiap momen perjalanan, mobilitas, dan konektivitas menjadi lebih berkualitas.',
    },
    {
      title: 'Awards',
      link: 'https://daihatsu.co.id/profile/award/',
      image: require('./assets/images/menu/dp-awards.jpg'),
      description:
        'Kami secara konsisten memberikan inovasi dan sentuhan terbaik untuk Sahabat. ',
    },
    {
      title: 'E-Magz',
      link: 'https://daihatsu.co.id/profile/e-magazine/',
      image: require('./assets/images/menu/dp-magz.jpg'),
      description:
        'Temukan cerita dan berita terbaru dari Sahabat untuk Sahabat di Daihatsu E-Magz!',
    },
    // {
    //   title: 'Brochures',
    //   link: 'https://daihatsu.co.id/profile/brosur/',
    //   image: require('./assets/images/menu/dp-brosur.jpg'),
    //   description: 'Temukan brosur mobil Daihatsu kesayanganmu di sini!',
    // },
    {
      title: 'CSR',
      link: 'https://daihatsu.co.id/profile/csr/',
      image: require('./assets/images/menu/dp-csr.jpg'),
      description:
        'Kami turut serta berperan dalam mengurangi dampak lingkungan dan membangun bisnis yang berkelanjutan untuk Indonesia dan Sahabat.',
    },
    {
      title: 'Contact Us',
      link: 'https://daihatsu.co.id/contact-us/',
      image: require('./assets/images/menu/dp-contact.jpg'),
      description: 'Tim kami siap untuk menanggapi semua pertanyaan Anda.',
    },
    {
      title: 'FAQ',
      link: 'https://daihatsu.co.id/tips-and-event/faq/',
      image: require('./assets/images/menu/dp-faq.jpg'),
      description:
        'Temukan jawaban dari pertanyaanmu melalui FAQ dari Sahabat.',
    },
  ],
}

export const shoppingTools = {
  title: 'Shopping Tools',
  subMenus: [
    {
      title: 'Price List',
      link: 'https://daihatsu.co.id/price-list/',
      description: 'Cek daftar harga mobil Daihatsu di sini.',
      image: require('./assets/images/menu/st-price.jpg'),
    },
    // {
    //   title: 'Credit Simulation',
    //   link: 'https://daihatsu.co.id/simulasi-kredit/',
    //   description:
    //     'Temukan harga dan cicilan terbaik melalui gambaran DP yang harus dibayarkan dan tenor setiap bulannya.',
    //   image: require('./assets/images/menu/st-kredit.jpg'),
    // },
    {
      title: 'Insurance Simulation',
      link: 'https://www.asuransiastra.com/garda-oto/Daihatsu-quote',
      external: true,
      description:
        'Cek jumlah premi asuransi mobil yang harus dibayar sesuai tipe asuransi yang kamu inginkan.',
      image: require('./assets/images/menu/st-asuransi.jpg'),
    },
    // {
    //   title: 'Promo',
    //   link: 'https://daihatsu.co.id/promo/',
    //   description:
    //     'Wujudkan impian membeli Daihatsu baru dengan harga terbaik untuk Sahabat.',
    //   image: require('./assets/images/menu/st-promo.jpg'),
    // },
    {
      title: 'Find Dealer',
      link: 'https://daihatsu.co.id/shopping/dealer/',
      description: 'Temukan dealer terdekat di sekitarmu.',
      image: require('./assets/images/menu/st-dealer.jpg'),
    },
    {
      title: 'Request Quote',
      link: 'https://daihatsu.co.id/request-quotes/',
      description:
        'Ajukan harga dan penawaran terbaik yang kamu inginkan sesuai spesifikasi dan anggaran milikmu.',
      image: require('./assets/images/menu/st-quotes.jpg'),
    },
    {
      title: 'Download Brochures',
      link: 'https://daihatsu.co.id/profile/brosur/',
      description:
        'Download brosur untuk melihat detail spesifikasi dan penawaran terbaik untuk mobil baru kamu.',
      image: require('./assets/images/menu/st-brosur.jpg'),
    },
    {
      title: 'Trade In',
      link: 'https://daihatsu.co.id/ownership/used-car/',
      description:
        'Tukar Daihatsu kamu sebelumnya dengan yang baru di event trade-in melalui dealer terdekat kamu.',
      image: require('./assets/images/menu/st-tradein.jpg'),
    },
    {
      title: 'Daihatsu Sparepart',
      link: 'https://daihatsu-sparepart.com',
      description:
        'Temukan spare part resmi untuk Daihatsu kamu di sini dengan cepat dan mudah serta dilengkapi dengan store/dealer terdekat.',
      image: require('./assets/images/menu/st-sparepart.jpg'),
      external: true,
    },
    // {
    //   title: 'Daihatsu Fleet',
    //   link: 'https://daihatsu.co.id/customer-fleet/',
    //   description:
    //     'Sahabat dapat memesan unit Daihatsu khusus untuk memenuhi kebutuhan operasional untuk mendukung bisnis/perusahaan Sahabat.',
    //   image: require('./assets/images/menu/st-fleet.jpg'),
    // },
  ],
}

export const ownership = {
  title: 'Ownership',
  subMenus: [
    {
      title: 'Daihatsu Event',
      link: 'https://daihatsu.co.id/tips-and-event/event/',
      image: require('./assets/images/menu/ow-event.jpg'),
      description:
        'Ikuti keseruan event online dan offline kami dan tentunya dengan beragam hadiah menarik untuk Sahabat!',
    },
    {
      title: 'Daihatsu News',
      link: 'https://daihatsu.co.id/program-and-event/press-release/',
      image: require('./assets/images/menu/ow-news.jpg'),
      description:
        'Update berita terbaru dari setiap kegiatan yang diselenggarakan untuk Sahabat.',
    },
    {
      title: 'Social Media',
      link: 'https://daihatsu.co.id/program-and-event/social-buzz/',
      image: require('./assets/images/menu/ow-social.jpg'),
      description:
        'Ikuti keseruan update, info, dan event terbaru dari Daihatsu melalui social media favoritmu!',
    },
    // {
    //   title: 'Daihatsu Club',
    //   link: 'https://daihatsu.co.id/klub/',
    //   image: require('./assets/images/menu/ow-club.jpg'),
    //   description:
    //     'Join komunitas Daihatsu dari seluruh pelosok negeri dan raih keseruan bersama Sahabat.',
    // },
  ],
}

export const afterSales = {
  title: 'After Sales',
  subMenus: [
    {
      title: 'Service Menu',
      link: 'https://daihatsu.co.id/ownership/service-menu/',
      description:
        'Daihatsu menyediakan beragam service untuk kenyamananmu berkendara. ',
      image: require('./assets/images/menu/as-service.jpg'),
    },
    {
      title: 'Owners Manual',
      link: 'https://daihatsu.co.id/ownership/owners-manual/',
      description:
        'Semua yang perlu kamu tahu sebagai pemilik Daihatsu sekarang ada di sini.',
      image: require('./assets/images/menu/as-om.jpg'),
    },
    {
      title: 'Daihatsu Mobile Apps',
      link: 'https://daihatsu.co.id/ownership/mobile-apps/',
      description: 'Booking service makin mudah dengan Daihatsu Mobile Apps.',
      image: require('./assets/images/menu/as-apps.jpg'),
    },
    {
      title: 'Daihatsu Mobile Service',
      link: 'https://daihatsu.co.id/ownership/mobile-services/',
      description:
        'Sibuk dan tidak punya waktu datang ke dealer? Teknisi kami siap mengunjungimu.',
      image: require('./assets/images/menu/as-mobile-services.jpg'),
    },
    {
      title: 'Daihatsu We Care',
      link: 'https://daihatsu.co.id/ownership/daihatsu-cares/',
      description:
        'Layanan khusus Daihatsu dengan lima program utama: booking service, mobile service, servis super cepat, part 24 jam atau gratis, dan garansi servis.',
      image: require('./assets/images/menu/as-cares.jpg'),
    },
    {
      title: 'Facilities',
      link: 'https://daihatsu.co.id/ownership/facilities/',
      description: 'Temukan fasilitas purnajual lainnya dari Daihatsu.',
      image: require('./assets/images/menu/as-facilities.jpg'),
    },
    {
      title: 'Booking Service',
      link: 'https://daihatsu.co.id/ownership/booking-service/',
      description:
        'Isi formulir untuk mendaftar sesi servis di dealer terdekat.',
      image: require('./assets/images/menu/as-booking.jpg'),
    },
    // {
    //   title: 'Daihatsu Genuine Oil',
    //   link: 'https://daihatsu.co.id#',
    //   description:
    //     'Kami merekomendasikan penggunaan oli terbaik demi kelancaran berkendara.',
    //   image: require('./assets/images/menu/as-oil.jpg'),
    // },
    {
      title: 'Recall Program',
      link: 'https://recall.daihatsu.co.id',
      description:
        'Wujud komitmen kami untuk menjaga kualitas kendaraan. Cek apakah kendaraanmu termasuk dalam recall campaign di sini.',
      image: require('./assets/images/menu/as-recall.jpg'),
      external: true,
    },
  ],
}

export const vehicles = {
  title: 'Vehicles',
  subMenus: [],
}
