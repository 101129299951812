<template>
  <div
    class="
      w-full
      h-[calc(100vh-82px)]
      fixed
      inset-0
      top-[82px]
      bg-cover
      backdrop-blur-lg
      z-10
      flex
      menu
    "
  >
    <div class="w-[294px] border-r-2">
      <div
        v-for="(menu, index) in menus"
        :key="index"
        class="
          pl-20
          py-[18px]
          font-bold
          text-black
          cursor-pointer
          hover:bg-[#e0e0e0]
        "
        :class="activeMenuIndex === index ? 'bg-[#e0e0e0]' : ''"
        @click="selectMenu(index)"
      >
        {{ menu.title }}
      </div>
    </div>
    <div class="w-[350px] border-r-2">
      <div class="p-10">
        <div class="font-bold text-xl mb-5">
          {{ menus[activeMenuIndex].title }}
        </div>
        <p class="text-sm mb-10">{{ menus[activeMenuIndex].description }}</p>
        <a
          :href="menus[activeMenuIndex].link"
          class="
            btn-primary
            py-3
            px-10
            rounded-lg
            text-white
            shadow-lg shadow-gray-300
            inline-flex
            justify-center
            items-center
          "
          :target="menus[activeMenuIndex].external ? 'blank' : ''"
          @click="$emit('close')"
        >
          Selengkapnya
        </a>
      </div>
    </div>
    <div class="flex-1">
      <!-- <TransitionFade> -->
      <img
        :key="menus[activeMenuIndex].link"
        :src="menus[activeMenuIndex].image"
        class="w-full h-full object-cover"
        alt="Menu Daihatsu"
      />
      <!-- </TransitionFade> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeMenuIndex: 0,
    }
  },
  methods: {
    selectMenu(index) {
      this.activeMenuIndex = index
    },
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.menu {
  background-image: url('../../assets/images/bg/bg-menu.png');
}
</style>
