var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    hidden\n    lg:flex\n    fixed\n    inset-x-0\n    z-50\n    px-20\n    justify-between\n    items-center\n    h-[82px]\n    bg-opacity-75\n    text-white\n    transition-colors\n    duration-400\n    font-ubuntu\n  ",class:_vm.solidNavbar && 'bg-white backdrop-blur-lg text-text-primary shadow'},[_c('ul',{staticClass:"z-10 flex space-x-4 flex-1"},_vm._l((_vm.mainMenus),function(menu,index){return _c('li',{key:index,staticClass:"font-medium pl-3 first:pl-0",class:_vm.activeMainMenuIndex === index
          ? 'relative after:absolute after:inset-0 after:translate-y-[30px] after:border-b-4 after:border-primary text-primary'
          : ''},[(menu.link)?_c('a',{staticClass:"hover:text-primary",attrs:{"href":menu.link},on:{"click":_vm.closeAllMenu}},[_vm._v(_vm._s(menu.title))]):_c('div',{staticClass:"cursor-pointer flex items-center group",on:{"click":function($event){return _vm.toggleMainMenu(index)}}},[_c('div',{staticClass:"group-hover:text-primary"},[_vm._v(" "+_vm._s(menu.title)+" ")]),_c('img',{staticClass:"h-5 w-5 ml-2 group-hover:filter-primary",class:{
            'rotate-180': _vm.activeMainMenuIndex === index,
            'filter-white': !_vm.solidNavbar,
          },attrs:{"src":require("../assets/images/svg/chevron-up.svg"),"alt":"Arrow"}})])])}),0),_c('a',{staticClass:"z-10",attrs:{"href":"https://daihatsu.co.id"},on:{"click":_vm.closeAllMenu}},[_c('img',{staticClass:"w-[113px] h-[38px] mx-16",attrs:{"src":require("../assets/images/daihatsu-logo.svg"),"width":"113","height":"38","alt":"Logo Daihatsu"}})]),_c('div',{staticClass:"flex z-10 items-center space-x-[42px] flex-1 justify-end"},[_c('img',{staticClass:"w-[18px] h-[17px] cursor-pointer hover:filter-primary",class:_vm.solidNavbar ? '' : 'filter-white',attrs:{"src":require("../assets/images/search.svg"),"alt":"search icon"},on:{"click":_vm.toggleSearchMenu}}),_c('img',{staticClass:"h-[26px] w-[26px] cursor-pointer hover:filter-primary",class:{
        'filter-white': !_vm.solidNavbar,
        'filter-primary': _vm.secondaryMenuIsActive,
      },attrs:{"src":require("../assets/images/menu.svg"),"alt":"Menu"},on:{"click":_vm.toggleSecondaryMenu}})]),(_vm.activeMainMenuIndex === 0)?_c('DropdownVehicles',{attrs:{"products":_vm.products},on:{"close":_vm.closeAllMenu}}):_vm._e(),(_vm.activeMainMenuIndex > 0)?_c('DropdownMenu',{key:_vm.activeMainMenuIndex,attrs:{"menus":_vm.mainMenus[_vm.activeMainMenuIndex].subMenus},on:{"close":_vm.closeAllMenu}}):_vm._e(),(_vm.secondaryMenuIsActive)?_c('DropdownNestedMenu',{attrs:{"menus":_vm.secondaryMenus},on:{"close":_vm.closeAllMenu}}):_vm._e(),(_vm.searchMenuIsActive)?_c('DropdownSearch',{attrs:{"products":_vm.products},on:{"close":_vm.toggleSearchMenu}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }