<template>
  <div
    class="fixed lg:hidden z-50 w-full font-ubuntu"
    :class="solidNavbar && 'bg-white'"
  >
    <div class="flex py-3 px-6 content-between items-center shadow-sm">
      <div
        v-if="aMenu.title"
        class="inline-flex items-center h-[35px]"
        @click="menuPath.pop()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <span>
          {{ aMenu.title }}
        </span>
      </div>
      <a v-else href="https://daihatsu.co.id">
        <img
          src="../assets/images/daihatsu-logo.svg"
          class="h-[35px]"
          alt="Logo Daihatsu"
        />
      </a>

      <div class="flex-1 flex justify-end items-center">
        <template v-if="!menuIsOpen">
          <!-- <a href="https://shop.daihatsu.co.id">
            <img
              src="../assets/images/keranjang.svg"
              class="w-6 h-6 cursor-pointer hover:filter-primary mr-5"
              :class="solidNavbar ? '' : 'filter-white'"
              alt="Keranjang Belanja"
            />
          </a> -->
          <img
            src="../assets/images/menu.svg"
            class="w-5 h-5 cursor-pointer hover:filter-primary"
            :class="solidNavbar ? '' : 'filter-white'"
            alt="Menu"
            @click="openMenu"
          />
        </template>
        <img
          v-else
          src="../assets/images/svg/close-circle.svg"
          class="w-5 h-5 cursor-pointer hover:filter-primary"
          :class="solidNavbar ? '' : 'filter-white'"
          alt="Menu"
          @click="closeMenu"
        />
      </div>
    </div>
    <template v-if="menuIsOpen">
      <div
        class="
          bg-[#F2FAFE]
          w-screen
          h-[calc(100vh-59px)]
          px-4
          py-5
          overflow-y-auto
          scrollable-content
        "
      >
        <div
          class="relative text-gray-300 focus-within:text-gray-400 w-full mb-9"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-4">
            <button class="p-1 focus:outline-none focus:shadow-outline">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                class="w-6 h-6"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <input
            v-model="search"
            type="search"
            name="q"
            class="
              py-4
              w-full
              text-sm text-gray-300
              bg-zinc-100
              rounded-full
              pl-14
              pr-4
              focus:outline-none focus:text-gray-900
            "
            placeholder="Search"
            autocomplete="off"
            @keypress.enter="runQuery"
          />
        </div>

        <ul>
          <li
            v-for="(menu, i) in aMenu.subMenus"
            :key="menu.title"
            class="text-zinc-600 mb-9"
          >
            <a v-if="!menu.subMenus" :href="menu.link" @click="closeMenu">
              {{ menu.title }}
            </a>
            <div
              v-else
              class="flex justify-between items-center"
              @click="menuPath.push(i)"
            >
              <div>{{ menu.title }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import {
  vehicles,
  shoppingTools,
  afterSales,
  profile,
  ownership,
} from '../menu.js'

const menus = [
  vehicles,
  shoppingTools,
  afterSales,
  { title: 'Tips Sahabat', link: '/tips-and-event/tips-sahabat' },
  profile,
  ownership,
]

export default {
  data() {
    return {
      solidNavbar: false,
      menuIsOpen: false,
      activeMenu: false,
      menuPath: [],
      scrollPosition: 0,
      search: '',
    }
  },
  computed: {
    aMenu() {
      if (!this.menuPath.length) {
        return { title: null, subMenus: menus }
      }

      return this.menuPath.reduce((prev, next) => prev[next], menus)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.updateNavbarTransparency()
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    openMenu() {
      this.solidNavbar = true
      this.menuIsOpen = true
      this.activeMenu = true
      this.menuPath = []
    },
    closeMenu() {
      this.menuIsOpen = false
      this.activeMenu = false
      this.menuPath = []
      this.updateNavbarTransparency()
    },
    updateNavbarTransparency() {
      if (window.scrollY > 100 || this.menuIsOpen) {
        this.solidNavbar = true
      } else {
        this.solidNavbar = true
      }
    },
    runQuery() {
      window.location.href = `https://daihatsu.co.id/search?q=${this.search}`
      this.closeMenu()
    },
    unlockScroll() {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('width')
      window.scroll({ top: this.scrollPosition, behavior: 'auto' })
    },

    lockScroll() {
      this.scrollPosition = window.pageYOffset
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = `-${this.scrollPosition}px`
      document.body.style.width = '100%'
    },
  },
  watch: {
    activeMenu(val) {
      if (val) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    },
  },
}
</script>

<style>
@import url('../assets/css/main.css');
</style>
