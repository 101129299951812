<template>
  <div
    class="
      py-3
      pb-20
      bg-gray-200
      backdrop-blur-lg
      fixed
      w-full
      left-0
      top-[82px]
      z-50
      hidden
      lg:block
    "
  >
    <div class="flex justify-end py-4 px-10">
      <button>
        <img
          src="../../assets/images/close.svg"
          alt="close"
          class="hover:filter-primary"
          @click="$emit('close')"
        />
      </button>
    </div>
    <div class="max-w-5xl mx-auto flex px-20 mb-8">
      <div class="flex-grow">
        <div class="flex items-center bg-white rounded-full py-3 px-5">
          <img
            class="h-[17px] filter-primary mr-4"
            src="../../assets/images/search.svg"
            alt="search icon"
          />
          <input
            v-model="query"
            type="text"
            class="outline-none w-full text-dark-gray"
            placeholder="Search here... "
            @keypress.enter="runQuery"
            @keyup="getQuery(query)"
          />
        </div>
        <div v-if="query" class="bg-white rounded-lg mt-1 p-4 divide-y">
          <div
            v-if="suggestedQuery.length"
            class="divide-y h-[200px] overflow-auto"
          >
            <div
              v-for="(item, i) in suggestedQuery"
              :key="i"
              class="flex items-center space-x-4 p-4"
              @click="updateQuery(item)"
            >
              <img :src="item.image" class="w-10 h-10 object-contain" alt="" />
              <p class="line-clamp-1">{{ item.name }}</p>
            </div>
          </div>
          <div v-else class="h-auto text-center text-dim-gray">
            Tidak ada hasil
          </div>
        </div>
      </div>
      <button
        class="
          btn-primary
          ml-10
          py-3
          px-10
          rounded-full
          self-start
          text-white
          shadow-lg shadow-gray-300
          disabled:grayscale disabled:cursor-not-allowed
        "
        @click="runQuery"
      >
        Search
      </button>
    </div>
    <div>
      <p class="font-bold text-center mb-8">Recently search...</p>
      <div class="flex justify-center space-x-4">
        <div
          v-for="history in histories"
          :key="history.id"
          class="h-36 w-36 object-contain"
          @click="updateQuery(history)"
        >
          <img :src="history.image" class="h-36 w-36 object-contain" alt="" />
          <p class="text-center font-bold line-clamp-1">{{ history.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'unfetch/polyfill'

export default {
  data() {
    return {
      query: '',
      histories: [],
      tips: [],
      products: [],
    }
  },
  methods: {
    runQuery() {
      window.open(`https://daihatsu.co.id/search?query=${this.query}`)
      this.$emit('close')
      localStorage.setItem('searchQuery', JSON.stringify(this.histories))
    },
    updateQuery(item) {
      this.query = item.name
      this.updateHistories(item)
      this.runQuery()
    },
    updateHistories(item) {
      this.histories.push(item)
      if (this.histories.length > 5) {
        this.histories.shift()
      }
    },
    async getQuery(query) {
      if (query) {
        const res = await fetch(
          `https://daihatsu.co.id/api/search/?query=${query}`
        )
        const { products, tips } = await res.json()

        this.products = products
        this.tips = tips
      } else {
        this.products = []
        this.tips = []
      }
    },
  },
  mounted() {
    const searchQuery = localStorage.getItem('searchQuery')
    if (searchQuery) {
      this.histories = JSON.parse(searchQuery)
    }
  },
  computed: {
    suggestedQuery() {
      if (this.query === '') {
        return []
      }

      return [
        ...this.products.map((product) => {
          return { name: product.name, image: product.image }
        }),
        ...this.tips.map((tip) => {
          return { name: tip.title, image: tip.image }
        }),
      ]
    },
  },
}
</script>

<style>
@import url('../../assets/css/main.css');
</style>

<style scoped>
::-webkit-scrollbar {
  @apply lg:w-2 lg:cursor-pointer;
}

::-webkit-scrollbar-track {
  @apply lg:bg-gray-300 lg:cursor-pointer rounded-full;
}
::-webkit-scrollbar-thumb {
  @apply lg:bg-gray-400 lg:rounded-full lg:cursor-pointer;
}
</style>
