<template>
  <div
    class="
      w-full
      h-[calc(100vh-82px)]
      fixed
      inset-0
      top-[82px]
      bg-cover
      backdrop-blur-lg
      z-10
      flex
      menu
    "
  >
    <ul class="w-[294px] border-r-2">
      <li
        v-for="(menu, index) in menus"
        :key="index"
        class="
          pl-20
          pr-5
          py-[18px]
          font-bold
          text-black
          cursor-pointer
          hover:bg-[#e0e0e0]
          flex
          justify-between
          items-center
        "
        :class="activeMenuIndex === index ? 'bg-[#e0e0e0]' : ''"
        @click="selectMenu(index)"
      >
        <div>
          {{ menu.title }}
        </div>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L1 9"
            stroke="#525252"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </li>
    </ul>
    <ul class="w-[294px] border-r-2">
      <li
        v-for="(menu, index) in menus[activeMenuIndex].subMenus"
        :key="index"
        class="
          px-10
          py-[18px]
          font-bold
          text-black
          cursor-pointer
          hover:bg-[#e0e0e0]
          flex
          justify-between
        "
        :class="activeSubMenuIndex === index ? 'bg-[#e0e0e0]' : ''"
        @click="selectSubMenu(index)"
      >
        <div>{{ menu.title }}</div>
      </li>
    </ul>
    <div class="flex-1">
      <!-- <TransitionFade> -->
      <div class="h-full relative">
        <img
          :key="menus[activeMenuIndex].subMenus[activeSubMenuIndex].link"
          :src="menus[activeMenuIndex].subMenus[activeSubMenuIndex].image"
          class="w-full h-full object-cover"
          alt="Menu Daihatsu"
        />
        <div
          class="
            bg-white/40 bg-cover
            backdrop-blur-lg
            h-full
            w-full
            max-w-[300px]
            absolute
            top-0
            left-0
          "
        >
          <div class="p-10">
            <div class="font-bold text-xl mb-5">
              {{ menus[activeMenuIndex].subMenus[activeSubMenuIndex].title }}
            </div>
            <p class="text-sm mb-10">
              {{
                menus[activeMenuIndex].subMenus[activeSubMenuIndex].description
              }}
            </p>
            <a
              :href="menus[activeMenuIndex].subMenus[activeSubMenuIndex].link"
              class="
                btn-primary
                py-3
                px-10
                rounded-lg
                text-white
                shadow-lg shadow-gray-300
                inline-flex
                justify-center
                items-center
              "
              @click="$emit('close')"
            >
              Selengkapnya
            </a>
          </div>
        </div>
      </div>
      <!-- </TransitionFade> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeMenuIndex: 0,
      activeSubMenuIndex: 0,
    }
  },
  methods: {
    selectMenu(index) {
      this.activeSubMenuIndex = 0
      this.activeMenuIndex = index
    },
    selectSubMenu(index) {
      this.activeSubMenuIndex = index
    },
  },
}
</script>
<style scoped>
.menu {
  background-image: url('../../assets/images/bg/bg-menu.png');
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
