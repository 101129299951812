export const cdnImg = (
  url,
  w = 720,
  h = 'auto',
  fit = 'crop',
  crop = 'center'
) => {
  const encodedUrl = encodeURI(url)
  const parsedUrl = new URL(encodedUrl)

  return `https://cdn-cms.daihatsu.co.id${parsedUrl.pathname}?w=${w}&h=${h}&fit=${fit}&crop=${crop}&auto=format,compress`
}

export const formatRupiah = (value) => {
  const split = value.toString().split('').reverse().join('')
  const condition = split.match(/\d{1,3}/g)
  return 'Rp ' + condition.join('.').split('').reverse().join('')
}
